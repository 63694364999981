import validate from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45required_45global from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/middleware/auth-required.global.ts";
import _02_45rbac_45global from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/middleware/02.rbac.global.ts";
import manifest_45route_45rule from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45required_45global,
  _02_45rbac_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "claim-lab": () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/middleware/claim-lab.ts")
}