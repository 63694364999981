
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91claimLine_9391z7OzMg3FJfD0pekd_45GPseeWosH8SVLbtJQ_45V3S4uUMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue?macro=true";
import { default as indexkf3mQtBFHykze81OO6oW2X_MRvLcIPl26mgdiKdgkBYMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue?macro=true";
import { default as _91claimId_93SAydzXEz8vnpSTInRiXia_K1VFDIKf9IMlVx4jkAdsIMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue?macro=true";
import { default as _91rid_93spi0KXyc4dTwh2M2_v49xtA9PcEddYmlJrXObJl2OGoMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue?macro=true";
import { default as _91connectorId_93GTdSlJj_45mR8fp8JiykxUD473kgwb0S_GnjY5M0_ZvhQMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as app_45status0dIwI7IHkwTMxAZi6OCWJ3O50YkLVPYmGiS7zOyd9Q8Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/app-status.vue?macro=true";
import { default as indexF9gCLuU_45kpPSh3hjCmmVviBvHMTvZzOCzbV9Chxwy1AMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/index.vue?macro=true";
import { default as auth_45callbacko4QM_45nsQ3QQx1N_aMOnHSXVHeDKKP4VZSvLBuiyU4t8Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginLJfsGKPjrHDRsYv4RKd_WwJAqrGt_451IkPfHduxo1sHIMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statustACt2y4z0ElBKTAQVsnQtsaUlBA9R_jkO0Eb3MjCnLAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: "connectorId",
    path: "/:connectorId()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue"),
    children: [
  {
    name: _91claimId_93SAydzXEz8vnpSTInRiXia_K1VFDIKf9IMlVx4jkAdsIMeta?.name,
    path: ":claimId()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue"),
    children: [
  {
    name: "connectorId-claimId-claimLine",
    path: ":claimLine()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue")
  },
  {
    name: "connectorId-claimId",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue")
  }
]
  },
  {
    name: "connectorId-claim-lab-claimId-rid",
    path: "claim-lab/:claimId()/:rid()",
    meta: _91rid_93spi0KXyc4dTwh2M2_v49xtA9PcEddYmlJrXObJl2OGoMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue")
  }
]
  },
  {
    name: "app-status",
    path: "/app-status",
    meta: app_45status0dIwI7IHkwTMxAZi6OCWJ3O50YkLVPYmGiS7zOyd9Q8Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/app-status.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/pages/index.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callbacko4QM_45nsQ3QQx1N_aMOnHSXVHeDKKP4VZSvLBuiyU4t8Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45loginLJfsGKPjrHDRsYv4RKd_WwJAqrGt_451IkPfHduxo1sHIMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statustACt2y4z0ElBKTAQVsnQtsaUlBA9R_jkO0Eb3MjCnLAMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  }
]