import revive_payload_client_lhfdnABIYryTg87LXGNa7NRaBLTMIYOYFhM602_h6cQ from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BLLHtcFum8fYaCc4wrjSFwKs6K8tFoRKqllkPVe9l8w from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uL804Hq8UpXE0rACCQgR3BS_Zf82BvLvlZhdE5qpWEg from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_lFWpk5AQUJzAzbfFxigYyVaCqdG0eaJMWWZ2jAzIDYM from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HzA5AIu__6xuGRJDB7qEKgFVo101_yArREGUBghRbbU from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DYyzpDsY2eSXJoVBSt7oylcI_83xN2NCr4IgNquHvNs from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_hj_G_G9_OgGE9q9tJ3Wxs4AtQdqs7VxLhDtR2DQMnuo from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_Z2V_qPm6eCl__QTvR46Ylw_GIDn62kwyfGPnXY1K1qc from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_O4e1b9ficiavh0CxoxQqHwda0inihzjM14B_ubfKzFA from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.36.0_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/.nuxt/components.plugin.mjs";
import prefetch_client_BG3YN_UU1dlwPe_VhN83x9xB5Rtdw_ApTPPH0l3rL1A from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII from "/builds/rialtic/qa/argocd-releases/console-ui/apps/disputes/.nuxt/unocss.mjs";
import plugin_DlpPMKJZLf_fIIteiqbY3yIVEupFWJuFIHByzemI2HU from "/builds/rialtic/qa/argocd-releases/console-ui/packages/protected/src/runtime/plugin.ts";
import pendo_tkGmWQoRDlhHENDAVi2Hn0Sq2CNzvYFYcsyj5v2SShU from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_SGheBrR9i4RmrpgBtOaw6N_f0GJBpOVIEbnJz18fybE from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_iV7PrbC1htQjhkNgsmxIwZrbi7G_UJeds9xfnbgHL3Q from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import plugin_qW_PdTb9mGBBa_Iir6NehiB2Z_jp0MgFoPvI2JUTMZY from "/builds/rialtic/qa/argocd-releases/console-ui/packages/ui/src/runtime/plugin.ts";
import plugin_zeh__hILNk_UO1ZfDUQBX1JIqMv0IHKyGlhI9fFIJGI from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.36.0__62b9539e7d1379537cbe2bd743b9a919/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
export default [
  revive_payload_client_lhfdnABIYryTg87LXGNa7NRaBLTMIYOYFhM602_h6cQ,
  unhead_BLLHtcFum8fYaCc4wrjSFwKs6K8tFoRKqllkPVe9l8w,
  router_uL804Hq8UpXE0rACCQgR3BS_Zf82BvLvlZhdE5qpWEg,
  payload_client_lFWpk5AQUJzAzbfFxigYyVaCqdG0eaJMWWZ2jAzIDYM,
  navigation_repaint_client_HzA5AIu__6xuGRJDB7qEKgFVo101_yArREGUBghRbbU,
  check_outdated_build_client_DYyzpDsY2eSXJoVBSt7oylcI_83xN2NCr4IgNquHvNs,
  view_transitions_client_hj_G_G9_OgGE9q9tJ3Wxs4AtQdqs7VxLhDtR2DQMnuo,
  chunk_reload_client_Z2V_qPm6eCl__QTvR46Ylw_GIDn62kwyfGPnXY1K1qc,
  plugin_vue3_O4e1b9ficiavh0CxoxQqHwda0inihzjM14B_ubfKzFA,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_BG3YN_UU1dlwPe_VhN83x9xB5Rtdw_ApTPPH0l3rL1A,
  unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII,
  plugin_DlpPMKJZLf_fIIteiqbY3yIVEupFWJuFIHByzemI2HU,
  pendo_tkGmWQoRDlhHENDAVi2Hn0Sq2CNzvYFYcsyj5v2SShU,
  datadog_SGheBrR9i4RmrpgBtOaw6N_f0GJBpOVIEbnJz18fybE,
  plugin_iV7PrbC1htQjhkNgsmxIwZrbi7G_UJeds9xfnbgHL3Q,
  plugin_qW_PdTb9mGBBa_Iir6NehiB2Z_jp0MgFoPvI2JUTMZY,
  plugin_zeh__hILNk_UO1ZfDUQBX1JIqMv0IHKyGlhI9fFIJGI
]